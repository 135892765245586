import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import testimonial2 from "@images/testimonials/testimonials-1-shape-4.png"; //for background image

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import alexaImage from "@images/technos/alexa.png";
import bardImage from "@images/technos/bard.png";
import lambdaImage from "@images/technos/lambda.png";
import langchainImage from "@images/technos/langchain.png";
import lexImage from "@images/technos/lex.png";
import midjourneyImage from "@images/technos/midjourney.png";
import openaiImage from "@images/technos/openai.png";
import pineconeImage from "@images/technos/pinecone.png";
import serpapiImage from "@images/technos/serpapi.png";

SwiperCore.use([Pagination, Autoplay]);

import { QAWHAT_INTEGRATION_DATA } from "@data";
const QawhatIntegration = () => {
  const { sectionTitle, paragraph,options } = QAWHAT_INTEGRATION_DATA;
  const CarouselOptions = {
    speed: 2000,
    spaceBetween: 30,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false
    },
    pagination: {
      el: "#app-shot-swiper-pagination",
      type: "bullets",
      clickable: "true"
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    style:{ textAlign: "center" },
    // Responsive breakpoints
    breakpoints: {
      0: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 300 },
      480: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 300 },
      600: { slidesPerView: 1, slidesPerGroup: 1, spaceBetween: 300 },
      991: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 300 },
      1000: { slidesPerView: 2, slidesPerGroup: 1, spaceBetween: 300 },
      1200: { slidesPerView: 3, slidesPerGroup: 1, spaceBetween: 300 }
    }
  };
  return (
    <section id="anticiper" className="service-one">
      <div
        className="testimonials-1-shape-4"
        style={{ backgroundImage: `url(${testimonial2})` }}
      ></div>
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>
        <div className="cta-two__text new-line">
          <p>{paragraph}</p>
        </div>
        <ul className="list-unstyled cta-two__list">
          {options.map(({ label }, index) => (
            <li key={`options-key-${index}`}>
              <i className="fa fa-check-circle"></i>
              {label}
            </li>
          ))}
        </ul>

        <Swiper className="app-shot-one__carousel owl-dot-type1" {...CarouselOptions}>
  
          <SwiperSlide className="app-shot-key-1">
            <img src={alexaImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-2">
            <img src={bardImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-3">
            <img src={lambdaImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-4">
            <img src={langchainImage} alt="" />
          </SwiperSlide>     
          <SwiperSlide className="app-shot-key-5">
            <img src={lexImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-6">
            <img src={midjourneyImage} alt="" />
          </SwiperSlide>
   
          <SwiperSlide className="app-shot-key-7">
            <img src={openaiImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-8">
            <img src={pineconeImage} alt="" />
          </SwiperSlide>
          <SwiperSlide className="app-shot-key-9">
            <img src={serpapiImage} alt="" />
          </SwiperSlide>


        </Swiper>
      </Container>
    </section>
  );
};

export default QawhatIntegration;
