import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const QawhatIntro = () => {
  return (
    <section className="banner-one" id="qawhat-intro">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
              <h3>
                QAWhat: <br />Le ChatBot sous stéroïdes!
               
              </h3>
              
              <p>
              QAWhat est la plateforme de Chat développée par Prompt Breeders.
              <br />Elle permet de tirer le meilleur des derniers modèles de langage (dont ChatGPT), tout en apportant des solutions concrètes à leurs risques et limites actuels : privacy, spécialisation, intégration.
              
              
              </p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default QawhatIntro;
