import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import QawhatIntro from "@components/qawhat-intro";
import QawhatPrivacy from "@components/qawhat-privacy";
import QawhatPerso from "@components/qawhat-perso";
import QawhatIntegration from "@components/qawhat-integration";
import Brands from "@components/brands";
import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";


const Qawhat = () => {
  return (
    <Layout pageTitle="QAWhat || Prompt Breeders">
      <Header />
      <QawhatIntro />
      <QawhatPrivacy />
      <QawhatPerso />
      <QawhatIntegration />
      <Contact />
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Qawhat;
